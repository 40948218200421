import NavMenu from "../components/NavMenu";
import Post from "../components/Post";
import FadeLayer from "../components/FadeLayer";
import Footer from "../components/Footer";
import store from "../store";

function Blog () {
    let state = store.getState();

    return (

        <div>
            <FadeLayer fadeState={state.fadeLayer} />
            <main className="main" id="main">
                <NavMenu />
                <div className="main-content blog">

                    <Post title=
                        {state.blogPosts.post5.title} 
                        subtitle={state.blogPosts.post5.subtitle}  
                        content={state.blogPosts.post5.content}
                        image={state.blogPosts.post5.image}
                    />

                    <Post title=
                        {state.blogPosts.post4.title} 
                        subtitle={state.blogPosts.post4.subtitle}  
                        content={state.blogPosts.post4.content}
                        image={state.blogPosts.post4.image}
                    />

                    <Post title=
                        {state.blogPosts.post3.title} 
                        subtitle={state.blogPosts.post3.subtitle}  
                        content={state.blogPosts.post3.content}
                        image={state.blogPosts.post3.image}
                    />

                    <Post title=
                        {state.blogPosts.post2.title} 
                        subtitle={state.blogPosts.post2.subtitle}  
                        content={state.blogPosts.post2.content}
                        image={state.blogPosts.post2.image}
                    />

                    <Post title=
                        {state.blogPosts.post1.title} 
                        subtitle={state.blogPosts.post1.subtitle}  
                        content={state.blogPosts.post1.content}
                        image={state.blogPosts.post1.image}
                    />

                    <Footer />
                </div>
            </main>
        </div>
        
    );
}

export default Blog;