import "../index.css";
import NavMenu from "../components/NavMenu";
import Square from "../components/Square";
import FadeLayer from "../components/FadeLayer";
import Footer from "../components/Footer";
import store from "../store";


function Portfolio () {
    const state = store.getState();

    return (

        <div>
            <FadeLayer fadeState={state.fadeLayer} />

            <main className="main" id="main">
                <NavMenu />
                <div className="main-content portfolio">

                    <span className="portfolio-category">
                        <h2>FRONT-END</h2>
                        <p>A collection of projects written in some combination of HTML, CSS, JS, REACT & REDUX</p>
                    </span>

                    <Square 
                        title={state.projects.proj8.title} 
                        icon={state.projects.proj8.icon} 
                        summary={state.projects.proj8.summary}
                        link={state.projects.proj8.link}
                    />

                    <Square 
                        title={state.projects.proj1.title} 
                        icon={state.projects.proj1.icon} 
                        summary={state.projects.proj1.summary}
                        link={state.projects.proj1.link}
                    />

                    <Square 
                        title={state.projects.proj2.title} 
                        icon={state.projects.proj2.icon} 
                        summary={state.projects.proj2.summary}
                        link={state.projects.proj2.link}
                    />

                    <Square 
                        title={state.projects.proj3.title} 
                        icon={state.projects.proj3.icon} 
                        summary={state.projects.proj3.summary} 
                        link={state.projects.proj3.link}
                    />


                    <span className="portfolio-category">
                        <h2>BACK-END</h2>
                        <p>A collection of projects. Written in JAVA, C++, PHP, SQL, PYTHON</p>
                    </span>

                    <Square 
                        title={state.projects.proj9.title} 
                        icon={state.projects.proj9.icon} 
                        summary={state.projects.proj9.summary}
                        link={state.projects.proj9.link}
                    />

                    <Square 
                        title={state.projects.proj10.title} 
                        icon={state.projects.proj10.icon} 
                        summary={state.projects.proj10.summary}
                        link={state.projects.proj10.link}
                    />

                    <Square 
                        title={state.projects.proj11.title} 
                        icon={state.projects.proj11.icon} 
                        summary={state.projects.proj11.summary}
                        link={state.projects.proj11.link}
                    />

                    
                    <Square 
                        title={state.projects.proj4.title} 
                        icon={state.projects.proj4.icon} 
                        summary={state.projects.proj4.summary}
                        link={state.projects.proj4.link}
                    />

                    <Square 
                        title={state.projects.proj5.title} 
                        icon={state.projects.proj5.icon} 
                        summary={state.projects.proj5.summary} 
                        link={state.projects.proj5.link}
                    />                

                    <span className="portfolio-category">
                        <h2>CODING CHALLENGES</h2>
                        <p>A showcase of efficient algorithm implementations</p>
                    </span>

                    <Square 
                        title={state.projects.proj6.title} 
                        icon={state.projects.proj6.icon} 
                        summary={state.projects.proj6.summary} 
                        link={state.projects.proj6.link}
                    />

                    <Square 
                        title={state.projects.proj7.title} 
                        icon={state.projects.proj7.icon} 
                        summary={state.projects.proj7.summary}
                        link={state.projects.proj7.link} 
                    />

                    <Footer />
                </div>
            </main>
        </div>   
    );
}

export default Portfolio;