

let Square = ({title, icon, summary, link}) => {

    return (
        <article className="square">
            <div className="project-icon">
                <i className={icon}></i>
            </div>
            <div>
                <h2>{title}</h2>
                {summary}

                {/* this is an imported link object with imported css animations from codepen */}
                <a href={link} class="link" target="_blank">
                    <span class="mask">
                        <div class="link-container">
                        <span class="link-title1 title">SEE MORE</span>
                        <span class="link-title2 title">SEE MORE</span>
                        </div>
                    </span>
                    <div class="link-icon">
                        <svg class="icon" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                        <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                        </svg>
                        <svg class="icon" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                        <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                        </svg>
                    </div>
                </a>
            </div>
        </article>
    )
}

export default Square;


