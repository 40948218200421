import store from "../store";
import { closeNav } from "../actions";

// This component animates the fade effect when a user clicks the nav menu, upon clicking
// any where on the faded layer, the close nav function will activate and the nav will close, this
// page will transition to transparent and a z-index of -1
let FadeLayer = ({fadeState}) => {

    // functions to close nav bar
    function closeMe() {
        store.dispatch(closeNav());
    }
    
    return (
        <div className={fadeState} onClick={closeMe}>
        </div>
    )
}

export default FadeLayer;