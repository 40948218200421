import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from '../src/containers/App.js';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';


// Change rendering into a function that can be called upon a user action 
const render = () => {
  fancyLog();
  return ReactDOM.render(<App />, document.getElementById('root'));
};

render();
store.subscribe(render);

// fancylog function to log the state from the store
function fancyLog() {
  console.log("%c Rendered with ? ??", "background: purple; color: #FFF");
  console.log(store.getState());
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
