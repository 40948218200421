import store from "../store";
import "../index.css";
import NavMenu from "../components/NavMenu";
import Square from "../components/Square";
import Footer from "../components/Footer";
import FadeLayer from "../components/FadeLayer";
import Intro from "../components/Intro";

function Home () {
    const state = store.getState();

    return (
        <div>
            <FadeLayer fadeState={state.fadeLayer} />

        <main className="main" id="main">
            <NavMenu />
            
            <div className="main-content">

                <article className="square blank"> </article>

                <Intro 
                        summary={state.intro} 
                />

                <Square 
                        title={state.projects.proj9.title} 
                        icon={state.projects.proj9.icon} 
                        summary={state.projects.proj9.summary}
                        link={state.projects.proj9.link}
                />
                <Square 
                        title={state.projects.proj10.title} 
                        icon={state.projects.proj10.icon} 
                        summary={state.projects.proj10.summary}
                        link={state.projects.proj10.link}
                />
                <Square 
                        title={state.projects.proj8.title} 
                        icon={state.projects.proj8.icon} 
                        summary={state.projects.proj8.summary} 
                        link={state.projects.proj8.link}
                    />
                <Square 
                        title={state.projects.proj4.title} 
                        icon={state.projects.proj4.icon} 
                        summary={state.projects.proj4.summary} 
                        link={state.projects.proj4.link}
                    />

                <Footer />

            </div>
        </main>
        
        </div>
    
        
    );
}

export default Home;