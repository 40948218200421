import React from 'react';

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentToggle: "content",
      toggleChevron: "fas fa-chevron-down"
    };
    this.toggleView = this.toggleView.bind(this);
  }

  toggleView() {
    if (this.state.currentToggle == "content") {
      this.setState({
        currentToggle: "content content-open",
        toggleChevron: "fas fa-chevron-up"
      });
    } else {
      this.setState({
        currentToggle: "content",
        toggleChevron: "fas fa-chevron-down"
      });
    }
  }

  render() {
    console.log(this.state.currentToggle);
    return (
      <div className="blog-post">
        <h2 className="blog-title">{this.props.title}</h2>
        <h3 className="blog-subtitle">{this.props.subtitle}</h3>
        <button 
            type="button" 
            className="collapsible" 
            onClick={this.toggleView}
        >
          <i className={this.state.toggleChevron}></i>
        </button>
        <div className={this.state.currentToggle}>
          <img
            src={this.props.image}
            alt="random image"
            width="100%"
            srcSet=""
          />
          {this.props.content}
        </div>
      </div>
    );
  }
}


  

export default Post;



