import Blog from './Blog';
import Home from './Home';
import Portfolio from './Portfolio';
import store from '../store'
import CONSTANTS from '../constants';
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {
  let state = store.getState();

  // function to import fontAwesome CDN;
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://kit.fontawesome.com/61144d7783.js";
    script.crossOrigin = "anonymous";
    script.async = true;

    document.body.appendChild(script);

    return () => {
    document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://kit.fontawesome.com/61144d7783.js";
    script.crossOrigin = "anonymous";
    script.async = true;

    document.body.appendChild(script);

    return () => {
    document.body.removeChild(script);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog/:title' element={<Blog />} />
        <Route path='/portfolio' element={<Portfolio />} />
      </Routes>
    </Router>

  );

}

export default App;
