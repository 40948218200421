import {createStore} from "redux";
import reducer from "../reducers/index";


let initialState = {
    page: "home",
    navState: "nav-menu",
    fadeLayer: "fadeScreen",
    activePage: {
        home: "active-page",
        blog: "",
        portfolio: ""
    },
    active: "content",
    inactive: "",
    iconDisplay1: {
        display: "none;"
    },
    iconDisplay2: {
        display: "block"
    },
    blogPosts: {
        post1: {
            toggle: "content",
            title: "Old Ends, New Beginnings",
            image: 'https://res.cloudinary.com/dojuvapbw/image/upload/v1634635044/don-isiko-headshot_ctnubd.jpg',
            subtitle: "When one chapter of life ends, another begin",
            content: <div>
                <p>This website serves more than just the function of a Software Development portfolio.</p>
                <p>
                    It will also be a space for me to practice my hand in writing articles. 
                    I plan to write about a range of topics, from my views on technological 
                    trends to demonstrating my understanding of fundamental coding concepts 
                    I've come across and applied.
                </p>
                <p>
                    The aim is to do these monthly, and these articles will naturally be short 
                    to reflect the little time I will be able to put aside for them, but 
                    better small articles than no articles!
                </p>
                <p>Stay tuned, watch this space.</p>
            </div>
        },
        post2: {
            toggle: "content",
            title: "Knowledge Trees",
            image: "https://res.cloudinary.com/dojuvapbw/image/upload/v1638718173/brain-knowledge-tree_cec8lz.png",
            subtitle: "When things start coming together",
            content: <div>
                <p>
                    This week I've been thinking a lot about knowledge trees.
                </p>
                <p>
                    I like to think that my brain arranges knowledge into something 
                    like a very high dimensional knowledge tree, with weak connections 
                    and strong connections between all things.
                </p>
                <p>
                    Before the last two weeks at my Software Development boot camp, 
                    I was always studying elements of software development in isolation, 
                    an if-else or switch statement here and there, or some implementation 
                    of a design pattern. Despite feeling good about learning something new, 
                    I'd feel that I had no idea as to how I'd transition 
                    from point a (then) to point b; to deploying a fully working application.
                </p>
                <p>
                    So as I've been learning more about software development, security, DevOps, 
                    servers, networks, scripting (insert buzzword here), I've begun to feel 
                    like a fog is lifting over my head; that although I haven't reached my next 
                    destination, I can see it in the distance just before it disappears over the horizon.
                </p>
            </div>
        },
        post3: {
            toggle: "content",
            title: "Wonder-eyed at the world of Python",
            image: 'https://res.cloudinary.com/dojuvapbw/image/upload/v1639422818/code-1084923_1280_kmmt4m.png',
            subtitle: "The Possibilities are Endless with Python?",
            content: <div>
                <p>
                    This week I had a chance to get really stuck in with Python for the first time.
                </p>

                <p>
                    It was fun, Python was easier to pick up for me as I have past experience with 
                    JavaScript; though I have to say that having a professional with years of 
                    experience in Python was really useful. Out teach could provide insights that 
                    just aren't readily available from pre-recorded online resources or documentation.
                </p>

                <p>
                    We also touched on some of the more professional aspects of coding, like debugging, 
                    unit testing,  and integration testing. Our main challenge for the week was to 
                    create our own solution for a Budget App built in Python. We started off in teams in 
                    order to build a proof of concept together, simple in its form and function; then 
                    individually we took off in our own directions to apply the knowledge we learned. 
                    I succeeded in creating a workable, testable terminal-based application. It was a 
                    great learning experience that had me exploring in more detail the intricacies of 
                    the functions taught; never in my short history with coding has a function or syntax 
                    ever had one-dimensional use, never mention the countless modules available to support 
                    non-standard or tedious requirements.
                </p>

                <p>
                    What I came to realise from all this is that python has a wealth of modules and potential 
                    applications, from creating games to automating the opening and closing of applications 
                    on your OS, Chat Bots… if you can think of it, there's a module that can help you do it in 
                    Python. Moving forward I intend to keep this language close to hand in my repertoire of 
                    languages I'm fluent in. 
                </p>

                <p>
                    Until next week folks!
                </p>

            </div>
        },
        post4: {
            toggle: "content",
            title: "Choosing Projects that Challenge You",
            image: 'https://res.cloudinary.com/dojuvapbw/image/upload/v1639952284/hunting-seat-5031565_1920_sp1qj5.jpg',
            subtitle: "When growth peeks at you through the windows of your comfort zone.",
            content: <div>
                <p> Behind all the awesome YouTube  tutorials, amazing pre-built modules, and your favorite applications, there are
                    countless hours of hard work from people with a passion for coding and problem solving; who presumably were
                    once in a position like my own. They were once filled with enthusiasm towards the potential of code, lacking 
                    only the experience to execute (pun intended) the projects we see today. 
                </p>

                <p>
                    I'm often left feeling a little overwhelmed by all the new things I need to learn in order to reach a stage where I 
                    can consider myself to have a solid foundation in Software Development. They say the learning never ends, but 
                    the feeling when you look at an endless road ahead of you is entirely different from reading a description of it on a 
                    neatly formatted book or website.
                </p>

                <p>
                    This week I began taking on some more complicated programming tutorials, the first is a python application that
                    requires one to use virtual machines, a sourced and manually compiled python interpreter,  vagrant, a natural 
                    language processing tool called spacy, and a host of other tools I've not yet been introduced to at my current 
                    level of progress through the tutorial. The teacher in this particular tutorial makes no attempt at providing step-by-step instruction, and instead makes a point of skipping many elements that the student should be capable of 
                    searching and solving themselves. 
                </p>

                <p>
                    What this resulted in was many hours of googling, prodding, and pressing, checking forums and documentation 
                    , and generally being frustrated (read: uncomfortable) at my lack of comprehensive understanding.  There was a 
                    section from the audiobook of Will Smith's biography "Will" that covers a life lesson Will Smith and his brother 
                    learned from his father.
                </p>

                <p>
                    Will and his Brother were asked to rebuild a wall that was tens of feet long and many feet high. The task was 
                    daunting, they couldn't see how they were ever going to complete it. Day in, day out, they weren't given any 
                    reprieve from the daily brick laying to complete this humungous wall. Will even saw it as the ghetto version of 
                    the great wall of China. His Dad said something to them one day when he and his brother were venting their 
                    frustrations. He said to them:
                </p>

                <blockquote cite="https://quotefancy.com/quote/756890/Will-Smith-You-don-t-set-out-to-build-a-wall-You-don-t-say-I-m-going-to-build-the-biggest">
                    “You don’t set out to build a wall. You don't say ‘I’m going to build the biggest, baddest, greatest wall that’s ever 
                    been built.’ You don’t start there. You say, ‘I’m going to lay this brick as perfectly as a brick can be laid.’ You do 
                    that every single day. And soon you have a wall.”
                </blockquote >

                <p>
                    To not look at the wall, to focus only on laying that one brick in your hand as perfectly as possible, that one brick
                    in your hand should be all that matters. Soon, before I realise, I will have myself a goddamn wall.
                </p>

            </div>
        },
        post5: {
            toggle: "content",
            title: "The Budget App Series",
            image: 'https://res.cloudinary.com/dojuvapbw/image/upload/v1640537511/money-4068357_1280_fit6xx.png',
            subtitle: "Part I: The problem and my approach",
            content: <div>
                <p>
                    As part of my writing commitments, I will occasionally set out to 
                    write a series of posts that outline my approach to taking on coding 
                    challenges or that just help me to demonstrate my understanding of a 
                    particular concept.
                </p>
                <p>
                    The first topic I will address is a challenge I was introduced to on a 
                    Software Development Bootcamp that I'm currently attending. We were 
                    tasked with creating a budget app in python capable of a number of features 
                    specified through user stories; these are shown below:
                </p>

                <ul>
                    <li>
                        A deposit method that accepts an amount and description
                    </li>
                    <li>
                        A withdrawal method  that works like the deposit method, 
                        but the amount processed should be taken in as a negative number. 
                        If the amount in the withdrawal request is larger than the current balance, 
                        nothing should be processed and the method should return false; otherwise 
                        it should return true after the withdrawal takes place.
                    </li>
                    <li>
                        A method that returns the budget current balance
                    </li>
                    <li>
                        A transfer method that accepts an amount and another budget category as 
                        arguments. The method should add a withdrawal with the amount and the 
                        description Transfer to [Destination Budget Category]. The method should 
                        then add a deposit to the other budget category with the amount and the 
                        description "Transfer from [Source Budget Category]". If there are not 
                        enough funds, nothing should be added to either ledger. This method should 
                        return True if the transfer took place, and False otherwise.
                    </li>
                    <li>
                        A method that prints out a budget statement, detailing all successful 
                        transactions details, the budget category and the remaining total.
                    </li>
                </ul>

                <p>
                        The approach I want to take for this is using Test Driven Development
                        (TDD). As far as is reasonable I will write out the tests I intend to pass
                        first before fleshing out the class being tested and its methods.
                </p>
                <p>
                        VS CODE is my IDE of choice, and the primary language I will be using for
                        this challenge is Python, but there will also be elements of bash, and I
                        will be using Pytest to help me with the validation of tests.
                </p>
                <p>
                        I will set out my folder structure as follows:
                </p>

                <img className="content-img" src="https://res.cloudinary.com/dojuvapbw/image/upload/v1640538205/folder-structure_qqckp1.png" />

                <p>
                    Where my application files will be stored in programs, and my tests files in 
                    tests. The .pytest_cache, .coverage and README.md files are miscellaneous and 
                    can be ignored for the purposes of this writing exercise.
                </p>

                <p>
                    I aspire to have this project be neatly organised and easily
                    understandable, given its simple nature. In the next part, we will get
                    stuck into some of the initial stages of coding; and as we progress through
                    the project I will highlight some auxiliary elements of good practice, like
                    comments, version control, and general documentation.
                </p>
            </div>
        },
        postTemplate: {
            toggle: "content",
            title: "",
            image: '',
            subtitle: "",
            content: ""
        }

    },
    intro: (
        <div className="empty">
            <p className="empty">
                I'm Don, an HVAC & Sustainability Engineer turned Software Developer with a 
                focus on back-end development.  I enjoy coding, problem-solving and working 
                with diverse teams on meaningful projects.
            </p>
            <p>
                This site is both my portfolio and my space for blogging, 
                feel free to explore the world of technology with me!
            </p>
        </div>
    ),
    projects: {
        proj1:{
            title: "Skype Clone",
            icon: "fas fa-sms",
            link: "https://skype-clone-don-isiko.netlify.app/",
            summary: 
            (<p>A simple React-Redux chat application based 
                on the popular commercial offering by Skype</p>)
        },
        proj2:{
            title: "Documentation site",
            icon: "far fa-folder",
            link: "https://cdpn.io/Daoist-Wakanda/debug/rNLPjNL/vWARwGdEXVKk",
            summary: 
            <p>HTML & CSS static webpage based on a user 
                story requiring a php documentation site</p>
        },
        proj3:{
            title: "Quotation Machine",
            icon: "fas fa-book",
            link: "https://cdpn.io/Daoist-Wakanda/debug/wvqgzGE/LQMExgeGqXqk",
            summary: 
            <p>Simple React-Redux application that displays 
                famous quotes in a random order</p>
        },
        proj4:{
            title: "Dimmbar",
            icon: "fas fa-beer",
            link: "https://github.com/Daoist-W/budget-app",
            summary: 
            <p>Solution to Bootcamp coding challenge, 
                involves use of Java, JUnit &amp; git </p>
        },
        proj5:{
            title: "Trip cost calculator",
            icon: "fas fa-calculator",
            link: "https://github.com/Daoist-W/PHP-BOOK",
            summary: 
            <div className="empty">
                <p>Trip cost calculator written in PHP</p>
                <p></p>
            </div>
        },
        proj6:{
            title: "Design Patterns",
            icon: "fas fa-laptop-code",
            link: "https://github.com/Daoist-W/POSA",
            summary: 
            <p>Example Implementations of Design Patterns I am familiar with</p>
        },       
        proj7:{
            title: "Python Games",
            icon: "fas fa-laptop-code",
            link: "https://github.com/Daoist-W/python_games",
            summary: 
            <p>Repository of python games to exercise my coding skills with</p>
        },
        proj8:{
            title: "Management Front-end",
            icon: "fas fa-laptop-code",
            link: "https://github.com/Daoist-W/QDFP-FRONT-END/",
            summary: 
            (<p>A front-end implementation for the Management 
                database created in a previous project</p>)
        },
        proj9:{
            title: "Management Back-end",
            icon: "fas fa-server",
            link: "https://github.com/Daoist-W/QA-DFESW7-FINAL-PROJECT",
            summary: 
            (<p>A back-end springboot application that persists 
                validated request data onto a database with REST API</p>)
        },
        proj10:{
            title: "Data Ingestion App",
            icon: "fas fa-server",
            link: "https://github.com/Daoist-W/data-ingestion-app",
            summary: 
            (<p>Python application, extracts named entities from 
                a public database, stores to google firecloud. </p>)
        },
        proj11:{
            title: "Fun with bash!",
            icon: "fas fa-server",
            link: "https://github.com/Daoist-W/fun-with-bash",
            summary: 
            (<p>Repository of useful but beginner friendly scripts written in bash</p>)
        },

    }
}

let store = createStore(reducer, initialState);

export default store;