import { togglePost } from "../actions";
import CONSTANTS from "../constants";


export default function reducer(state = null, action) {
    switch (action.type) {
        case CONSTANTS.HOME_PAGE:
            return {
                ...state,
                activePage: {
                    home: "active-page",
                    blog: "",
                    portfolio: ""
                },
                page: action.payload
            };
        case CONSTANTS.PORTFOLIO:
            return {
                ...state,
                activePage: {
                    home: "",
                    blog: "",
                    portfolio: "active-page"
                },
                page: action.payload
            };
        case CONSTANTS.BLOG:
            return {
                ...state,
                activePage: {
                    home: "",
                    blog: "active-page",
                    portfolio: ""
                },
                page: action.payload
            };
        case CONSTANTS.OPEN_NAV:
            return {
                ...state,
                fadeLayer: "fadeScreen fadeIn",
                navState: "nav-menu nav-menu-open",
                iconDisplay1: {
                    display: "block"
                },
                iconDisplay2: {
                    display: "none"
                }
            };
        case CONSTANTS.CLOSE_NAV:
            return {
                ...state,
                fadeLayer: "fadeScreen",
                navState: "nav-menu",
                iconDisplay1: {
                    display: "none"
                },
                iconDisplay2: {
                    display: "block"
                }
            }
        default:
            return state;
    }
}
