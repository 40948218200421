import CONSTANTS from "../constants";

export const setToPortfolio = id => ({
    type: CONSTANTS.PORTFOLIO,
    payload: CONSTANTS.PORTFOLIO
});

export const setToHome = id => ({
    type: CONSTANTS.HOME_PAGE,
    payload: CONSTANTS.HOME_PAGE
});

export const setToBlog = id => ({
    type: CONSTANTS.BLOG,
    payload: CONSTANTS.BLOG
});

export const openNav = id => ({
    type: CONSTANTS.OPEN_NAV,
    payload: CONSTANTS.OPEN_NAV
})

export const closeNav = id => ({
    type: CONSTANTS.CLOSE_NAV,
    payload: CONSTANTS.CLOSE_NAV
})

export const toggleOpen = id => ({
    type: CONSTANTS.TOGGLE_OPEN,
    payload: CONSTANTS.TOGGLE_OPEN
})

export const toggleClose = id => ({
    type: CONSTANTS.TOGGLE_CLOSED,
    payload: CONSTANTS.TOGGLE_CLOSED
})

export const returnState = id => ({
    type: CONSTANTS.RETURN_STATE,
    payload: CONSTANTS.RETURN_STATE
})
