let CONSTANTS = {
    HOME_PAGE: "home",
    PORTFOLIO: "portfolio",
    BLOG: "blog",
    OPEN_NAV: "open navigation",
    CLOSE_NAV: "close navigation",
    TOGGLE_OPEN: "content content-open" ,
    TOGGLE_CLOSED: "content",
    RETURN_STATE: "returnState"
}

export default CONSTANTS;