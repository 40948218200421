import store from "../store";
import { Link } from "react-router-dom";
import { setToPortfolio, setToBlog, setToHome, openNav, closeNav } from "../actions";

let NavMenu = () => {

    let state = store.getState();

    const navStyle = {
        color: 'white',
        textDecoration: 'none',
        marginBottom: '1em',
        width: '0px',
        whiteSpace: 'nowrap'
    }

    // functions to keep track of active page
    const activeHome = () => {
        store.dispatch(setToHome());
    }

    const activePortfolio = () => {
        store.dispatch(setToPortfolio());
    }

    const activeBlog = () => {
        store.dispatch(setToBlog());
    }


    // functions to open and close nav bar

    function closeMe() {
        store.dispatch(closeNav());
    }

    function openMe() {
        store.dispatch(openNav());
        console.log("test");
    }

    return(
        <nav className="nav">
            <div className="nav-icons">
                <p id="close" style={state.iconDisplay1}><a className="closebtn" onClick={closeMe}><i className="fas fa-times" ></i></a></p>
                <p id="open" style={state.iconDisplay2}><a className="openbtn" onClick={openMe}><i className="fas fa-bars"></i></a></p>
                <p><i className="fas fa-search"></i></p>
                <div className="contacts">
                    <a href="https://twitter.com/Isiko_DK" target="_blank"><i className="fab fa-twitter-square"></i></a>
                    <a href="https://github.com/Daoist-W" target="_blank"><i className="fab fa-github-square"></i></a>
                    <a href="https://www.linkedin.com/in/don-i-b8155874/" target="_blank"><i className="fab fa-linkedin"></i></a>
                </div>
                
            
                
            </div>
            <div className={state.navState}>
                <h2>DON ISIKO</h2>
                <ul>
                    <li className={state.activePage.home}>
                        <Link style={navStyle} to="/" onClick={activeHome}>Home</Link>
                    </li>
                    <li className={state.activePage.portfolio}>
                        <Link style={navStyle} to="/portfolio" onClick={activePortfolio}>Portfolio</Link>
                    </li>
                    <li className={state.activePage.blog}>
                        <Link style={navStyle} to="/blog" onClick={activeBlog}>Blog</Link>
                    </li>
                </ul>
            </div>
            </nav>
    )
}

export default NavMenu;