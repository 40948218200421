

let Intro = ({title, icon, summary, link}) => {

    return (
        <article className="square">
            <div className="project-icon">
                <i className={icon}></i>
            </div>
            <div>
                <h2>{title}</h2>
                {summary}
            </div>
        </article>
    )
}

export default Intro;


