
let Footer = () => {
    return (
        <footer id="footer">
                    <div id="footer-contacts">
                        <a href="#"><i className="fab fa-twitter-square"></i></a>
                        <a href="#"><i className="fab fa-github-square"></i></a>
                        <a href="#"><i className="fab fa-linkedin"></i></a>
                    </div>
        </footer>
    )
}

export default Footer;
